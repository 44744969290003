.info {
  background-color: var(--color-gray-200);
  border-radius: 4px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  display: block;
}

.table {
  display: table;
  width: 100%;
}

.tr {
  display: table-row;

  &:last-child {
    .td {
      border-bottom: 0;
    }
  }

  @media (--mobile) {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    border-bottom: 1px solid var(--color-gray-300);
  }
}

.td {
  height: 53px;
  display: table-cell;
  vertical-align: middle;
  border-bottom: 1px solid var(--color-gray-300);
  padding-right: 10px;

  @media (--mobile) {
    border-bottom: 0;
    height: auto;
  }

  &:nth-child(1) {
    @media (--mobile) {
      width: 100%;
      padding-right: 40px;
      box-sizing: border-box;
    }
  }

  &:nth-child(2) {
    width: 1%;

    @media (--mobile) {
      margin-top: -2px;
      margin-bottom: -6px;

      &:empty {
        display: none;
      }
    }
  }

  &:nth-child(3) {
    width: 48px;
    padding-left: 24px;

    @media (--mobile) {
      width: 24px;
      height: 24px;
      padding: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto 0;
    }
  }
}
