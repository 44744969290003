.duration {
  display: flex;
  height: 36px;
  padding-top: 4px;
  padding-bottom: 4px;
  align-self: flex-start;
  width: max-content;
  box-sizing: border-box;

  &_center {
    align-items: center;
  }

  &_warning {
    .icon {
      color: var(--color-additional-500);
    }
  }

  &_expired {
    .icon {
      color: var(--color-negative-500);
    }
  }
}

.icon {
  margin-right: 8px;
  color: var(--color-gray-500);
}

.info {
  display: block;
  flex-shrink: 0;
}

.label {
  display: block;
  margin-bottom: 2px;
}
