.title {
  display: block;
  margin-top: 32px;
  margin-bottom: 24px;

  @media (--tablet) {
    margin-top: 24px;
  }

  @media (--mobile) {
    margin-left: 12px;
  }
}

.content {
  width: 824px;
  display: block;

  @media (--tablet) {
    width: 100%;
  }
}
