.text {
  display: block;
  color: inherit;
  cursor: inherit;
  text-align: inherit;

  &_size_l {
    font-size: 21px;
    line-height: 30px;
    letter-spacing: -0.3px;
  }

  &_size_m {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.3px;
  }

  &_size_s {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.3px;
  }

  &_size_xs {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.2px;
  }

  &_color_900 {
    color: var(--color-gray-900);
  }

  &_color_600 {
    color: var(--color-gray-600);
  }

  &_color_500 {
    color: var(--color-gray-500);
  }
}
