.form {
  display: block;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(3, 30, 120, 0.1);
  border-radius: 3px;
  width: 408px;
  box-sizing: border-box;
}

.text {
  display: block;
  margin-top: 8px;
  margin-bottom: 24px;
}

.field {
  display: block;
  margin-top: 8px;

  &:first-of-type {
    margin-top: 24px;
  }
}

.submit {
  margin-top: 24px;
  display: block;
}
