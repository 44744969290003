.label {
  display: flex;
  position: relative;
  cursor: pointer;
  color: var(--color-gray-800);
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.2px;

  &_icon_right {
    .icon {
      order: 1;
      margin-right: 0;
      margin-left: 4px;
    }
  }
}

.icon {
  margin-right: 4px;
  cursor: pointer;
}

.select {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}
