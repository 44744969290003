.form {
  display: block;
  padding: 40px;
  width: 408px;
  box-sizing: border-box;
  max-width: 100%;

  @media (--mobile) {
    width: 100%;
  }
}

.field {
  display: block;
  margin-top: 8px;

  &:first-of-type {
    margin-top: 24px;
  }
}

.security {
  margin-top: 24px;
  display: block;
}

.check {
  display: flex;
  margin-bottom: 12px;
  color: var(--color-gray-600);
  position: relative;
  padding-left: 36px;
  min-height: 24px;
  align-items: flex-start;
  padding-top: 3px;
  box-sizing: border-box;

  &:last-child {
    margin-bottom: 0;
  }

  &_valid {
    .checkIcon {
      background-color: var(--color-positive-500);
    }
  }
}

.checkIcon {
  display: inline-flex;
  margin-right: 12px;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-400);
  color: var(--color-white);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.submit {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
