.section {
  --indent: 24px;

  display: flex;
  flex-direction: column;
  background: var(--color-white);
  width: 100%;
  pointer-events: inherit;

  border-radius: 4px;

  @media (--mobile) {
    --indent: 12px;
  }

  &_level_0 {
    box-shadow: 0px 0px 2px 0px rgba(3, 30, 120, 0.1);
  }

  &_level_1 {
    box-shadow: 0px 1px 3px rgba(3, 30, 120, 0.1);
  }

  &_level_2 {
    box-shadow: 0px 2px 4px rgba(3, 30, 120, 0.1);
  }

  &_level_3 {
    box-shadow: 0px 8px 18px 5px rgba(3, 30, 120, 0.08);
  }
}
