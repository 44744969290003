.filters {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(3, 30, 120, 0.1);
  border-radius: 4px;
  height: 48px;
  display: flex;
  padding-left: 16px;
  padding-right: 24px;
  align-items: center;
  width: max-content;
  min-width: 100%;
  box-sizing: border-box;
}

.tabs {
  align-self: flex-end;
}

.selects {
  margin-left: auto;
  display: flex;

  > * {
    margin-left: 8px;
  }
}
