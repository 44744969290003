.content {
  width: 100%;
  max-width: 898px;
  display: block;
  margin: 0 auto;
}

.title {
  display: block;
  margin-top: 32px;

  @media (--tablet) {
    margin-top: 24px;
  }

  @media (--mobile) {
    margin-left: 12px;
    margin-top: 16px;
  }
}

.description {
  margin-top: 8px;
  margin-bottom: 24px;
  display: block;

  @media (--mobile) {
    margin-left: 12px;
    margin-bottom: 12px;
  }
}

.filters {
  display: block;
  margin-bottom: 8px;
}

.courses {
  margin-top: 16px;

  @media (--mobile) {
    margin-top: 12px;
  }

  > * {
    margin-bottom: 1px;
  }
}

.course {
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  transition: background-color 0.3s ease;
  box-sizing: border-box;

  @media (--mobile) {
    height: auto;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &:hover {
    background-color: var(--color-gray-100);
    cursor: pointer;
  }
}

.courseInfo {
  display: flex;

  @media (--mobile) {
    margin-top: 12px;
  }
}

.courseParam {
  margin-right: 12px;
  color: var(--color-gray-500);
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  > svg {
    margin-right: 8px;
  }
}
