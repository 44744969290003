.list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.items {
  display: block;
  width: 100%;
  margin-top: 8px;
  border-top: 1px solid var(--color-gray-300);
  max-height: 343px;
  overflow-y: auto;
}

.item {
  display: flex;
  height: 49px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-300);
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;

  &:hover {
    background-color: var(--color-gray-100);
  }

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.radio {
  appearance: none;

  &:checked ~ .check {
    opacity: 1;
  }
}

.check {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--color-primary-500);
  margin-left: auto;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: inherit;
  opacity: 0;
  transition: opacity 0.3s ease;
}
