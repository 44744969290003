.container {
  --sidebar-width: 90px;

  display: grid;
  min-height: 100vh;
  padding-left: var(--sidebar-width);

  @media (--tablet) {
    --sidebar-width: 0;
    padding-bottom: 65px;
    display: block;
  }

  @media (--mobile) {
    padding-bottom: 55px;
  }
}

.sidebar {
  width: var(--sidebar-width);
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;

  @media (--tablet) {
    bottom: 0;
    left: 0;
    right: 0;
    height: 65px;
    width: 100vw;
  }

  @media (--mobile) {
    height: 55px;
  }
}

.content {
  display: block;
  width: calc(100vw - var(--sidebar-width));
}
