.toast {
  --offset: 40px;

  position: fixed;
  width: max-content;
  max-width: calc(100vw - 40px);
  top: calc(-1 * var(--offset));
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  border-radius: 2px;
  background-color: var(--color-negative-500);
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 14px;
  padding-right: 8px;
  color: var(--color-white);
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 1;

  &_visible {
    transform: translate3d(
      0,
      calc(var(--offset) + 40px + var(--index, 0) * 50px),
      0
    );
  }

  &_closed {
    opacity: 0;
    pointer-events: none;
  }

  &:focus {
    outline: none !important;
  }
}

.icon {
  pointer-events: inherit;
  color: inherit;
  margin-right: 6px;
  flex-shrink: 0;
}

.children {
  pointer-events: inherit;
  display: block;
  flex-grow: 1;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: inherit;
}

.button {
  pointer-events: inherit;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  height: 28px;
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 16px;
  color: inherit;
  margin-left: 16px;
  flex-shrink: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
