.tooltip {
  display: block;
  color: inherit;
  position: relative;

  &::after,
  &::before {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  &::before {
    content: '';
    bottom: 100%;
    margin-bottom: 2px;
    border-top: 4px solid var(--color-gray-600);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }

  &::after {
    content: '' attr(aria-label);
    bottom: 100%;
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.184615px;
    color: var(--color-white);
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    background-color: var(--color-gray-600);
    white-space: nowrap;
  }

  &:hover {
    &::after,
    &::before {
      opacity: 1;
    }
  }
}
