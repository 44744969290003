.content {
  width: 661px;
  max-width: 100vw;
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
}

.icon {
  display: flex;
  margin-bottom: 24px;
  color: var(--color-primary-500);
  background-color: var(--color-gray-200);
  width: 72px;
  height: 72px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.button {
  margin-top: 24px;
  display: block;
  width: 222px;
}
