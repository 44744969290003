.container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;

  &_withIcon {
    .input {
      padding-left: 44px;
    }
  }

  &_labeled .input:focus,
  &_labeled&_filled .input {
    padding-top: 26px;

    &::placeholder {
      opacity: 0;
    }

    ~ .label {
      opacity: 1;
    }
  }

  &_error {
    .input {
      border-color: var(--color-negative-500);
      background-color: var(--color-negative-100);
    }
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  display: block;
}

.icon {
  left: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  color: var(--color-gray-500);
}

.input {
  height: 52px;
  width: 100%;
  border-radius: 4px;
  background: var(--color-gray-200);
  border: 1px solid var(--color-gray-200);
  transition: border-color 0.3s ease;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  color: var(--color-gray-900);
  box-sizing: border-box;

  &:hover {
    border-color: var(--color-primary-200);
  }

  &:focus {
    border-color: var(--color-primary-500);
  }

  &::placeholder {
    color: var(--color-gray-500);
    opacity: 1;
  }
}

.label {
  position: absolute;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.2px;
  color: var(--color-gray-600);
  left: 16px;
  top: 10px;
  opacity: 0;
}

.clear {
  width: 36px;
  height: 36px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 8px;
  cursor: pointer;
  color: var(--color-gray-500);
  align-items: center;
  justify-content: center;
}

.error {
  display: block;
  margin-top: 4px;
  font-size: 11px;
  line-height: 13px;
  color: var(--color-negative-500);
}
