.content {
  width: 100%;
  max-width: 851px;
  display: block;
  margin: 0 auto;
  padding-top: 32px;

  @media (--mobile) {
    padding-top: 16px;
  }
}

.course {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 40px;

  @media (--mobile) {
    padding: 12px;
  }
}

.description {
  display: block;
  margin-top: 8px;
}

.skill {
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  height: 22px;
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  background-color: var(--color-gray-200);
  color: var(--color-gray-600);
  border-radius: 2px;
}

.params {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;

  @media (--mobile) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;

    button {
      width: 100%;
    }
  }
}

.courseInfo {
  display: flex;

  @media (--mobile) {
    margin-bottom: 24px;
  }
}

.courseParam {
  margin-right: 12px;
  color: var(--color-gray-500);
  display: flex;
  align-items: center;

  @media (--mobile) {
    margin-right: 40px;
  }

  &:last-child {
    margin-right: 0;
  }

  > svg {
    margin-right: 8px;
  }
}

.lessons {
  display: block;
  margin-top: 16px;
  counter-reset: lesson;

  > * {
    margin-bottom: 1px;
  }
}

.lesson {
  height: 74px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 40px;
  position: relative;

  &::before {
    counter-increment: lesson;
    content: '' counter(lesson) '';
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.2px;
    color: var(--color-gray-600);
    position: absolute;
    left: 16px;
  }
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
  background-color: var(--color-gray-200);
  color: var(--color-gray-600);

  &_exam {
    background-color: var(--color-additional-100);
    color: var(--color-additional-500);
  }
}
