.link {
  height: 31px;
  margin-top: 24px;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: var(--color-gray-700);
  margin-left: -9px;

  @media (--mobile) {
    margin-left: 12px;
  }
}

.linkIcon {
  margin-right: 4px;
  color: inherit;
}

.title {
  display: block;
  margin-top: 8px;
  margin-bottom: 24px;

  @media (--tablet) {
    margin-top: 24px;
  }

  @media (--mobile) {
    margin-left: 12px;
  }
}

.content {
  width: 100%;
  max-width: 1222px;
  display: block;
}

.filters {
  display: block;
  margin-bottom: 8px;
  overflow-y: auto;
}
