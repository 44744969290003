.page {
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-gray-200);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.logo {
  display: block;
  position: absolute;
  top: 45px;
  left: 48px;
  cursor: pointer;
}
