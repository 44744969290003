.skeletonItem {
  --start: var(--color-gray-200);
  --end: var(--color-gray-300);

  display: block;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-color: var(--start);
  overflow: hidden;
  position: relative;

  &_theme_300 {
    --start: var(--color-gray-300);
    --end: var(--color-gray-400);
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--end);
    opacity: 0;
    animation-name: skeleton-glow;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: backwards;
    animation-play-state: paused;
  }

  &_loading {
    &::after {
      animation-play-state: running;
    }
  }
}

@keyframes skeleton-glow {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
