.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 300px;
  padding-top: 22px;
  padding-bottom: 22px;
}

.image {
  display: block;
  margin-bottom: 24px;
}

.text {
  margin-top: 8px;
  display: block;
  font-size: 16px;
  line-height: 25px;
  color: var(--color-gray-600);
  letter-spacing: -0.3px;
}

.button {
  width: 212px;
  margin-top: 32px;
  display: block;
}
