.content {
  width: 422px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
}

.icon {
  display: block;
  margin-bottom: 16px;
  color: var(--color-additional-500);
}

.info {
  text-align: inherit;
  margin-top: 8px;
}

.label {
  font: inherit;
  color: var(--color-gray-700);
}

.button {
  margin-top: 16px;
  display: block;
  width: 222px;
}
