.button {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  color: var(--color-gray-600);
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: var(--color-gray-100);
  }

  &_active {
    background-color: var(--color-gray-200);
  }
}

.buttonContent {
  position: relative;
  display: block;
  cursor: inherit;
}

.buttonCount {
  display: inline-block;
  background-color: var(--color-negative-500);
  border-radius: 50%;
  height: 19px;
  min-width: 19px;
  left: 13px;
  bottom: 8px;
  position: absolute;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: var(--color-white);
  cursor: inherit;
}

.container {
  position: fixed;
  top: 68px;
  bottom: 7px;
  right: 8px;
  display: flex;
  align-items: stretch;
  width: 466px;
  max-width: 100%;
  z-index: 1;
  transform: translate3d(calc(100% + 8px), 0, 0);
  transition: transform 0.5s ease;

  @media (--tablet) {
    bottom: 73px;
  }

  @media (--mobile) {
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }

  &_visible {
    transform: translate3d(0, 0, 0);
  }
}

.inner {
  display: block;
  padding-top: 24px;
  padding-bottom: 24px;
  height: 100%;
  overflow-y: auto;
  position: relative;

  @media (--mobile) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.title {
  display: block;
  padding-left: 24px;
  margin-bottom: 16px;

  @media (--mobile) {
    padding-left: 12px;
  }
}

.group {
  display: block;
  border-bottom: 1px solid var(--color-gray-300);
  margin-bottom: 24px;
  padding-left: 24px;
  padding-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
  }

  @media (--mobile) {
    padding-left: 12px;
  }
}

.groupTitle {
  display: block;
  margin-bottom: 8px;
}

.notification {
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 44px;
  position: relative;

  @media (--mobile) {
    padding-right: 32px;
  }

  &_unread {
    &::after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      margin-top: -6px;
      right: 16px;
      background: var(--color-primary-500);

      @media (--mobile) {
        right: 8px;
      }
    }
  }
}

.notificationIcon {
  width: 40px;
  height: 40px;
  background-color: var(--color-gray-200);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray-400);
  margin-right: 16px;
  flex-shrink: 0;
  border-radius: 50%;

  @media (--mobile) {
    margin-right: 12px;
  }
}

.notificationText {
  display: block;
  margin-bottom: 4px;
}

.close {
  display: none;

  @media (--mobile) {
    display: block;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
