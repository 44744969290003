.button {
  display: inline-flex;
  border-radius: 50%;
  color: var(--color-gray-500);
  transition: background-color 0.3s ease, border-color 0.3s ease;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &:not(:disabled):hover {
    background-color: rgba(226, 231, 238, 0.65);
    cursor: pointer;
  }

  &:not(:disabled):active {
    background-color: var(--color-gray-300);
  }

  &_size_xl {
    width: 42px;
    height: 42px;
  }

  &_color_white {
    color: var(--color-white);

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  &_activated {
    color: var(--color-primary-500);
  }
}

.icon {
  margin-right: 8px;
  color: inherit;
  cursor: inherit;
}
