.sidebar {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-800);
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: auto;

  @media (--tablet) {
    flex-direction: row;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;

  @media (--tablet) {
    display: none;
  }
}

.nav {
  display: block;
  width: 100%;

  @media (--tablet) {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.list {
  display: block;

  @media (--tablet) {
    display: flex;
  }
}

.listItem {
  display: flex;
  flex-shrink: 0;

  @media (--tablet) {
    width: 130px;
    align-items: center;
  }

  @media (--mobile) {
    width: 80px;
  }

  &_mobile {
    display: none;

    @media (--mobile) {
      display: flex;
    }
  }
}

.link {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -0.2px;
  color: var(--color-gray-500);
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 18px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (--tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &:hover {
    background: rgba(34, 38, 49, 0.4);
  }

  &_active {
    color: var(--color-white);

    .icon {
      color: var(--color-primary-400);
    }
  }
}

.icon {
  display: block;
  margin-bottom: 12px;
  color: inherit;
  cursor: inherit;

  @media (--tablet) {
    margin-bottom: 4px;
  }
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 55px;
  background: var(--color-gray-800);
  display: flex;
  padding-top: 16px;
  flex-direction: column;
}

.menuItem {
  font-size: 18px;
  line-height: 22px;
  color: var(--color-gray-300);
  display: flex;
  height: 50px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  &:last-child {
    margin-top: auto;
  }
}

.menuIcon {
  margin-right: 16px;
  display: block;
  color: var(--color-gray-600);
}
