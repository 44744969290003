.content {
  padding-left: var(--indent);
  padding-right: var(--indent);
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
}

.photo {
  margin-right: 24px;
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 50%;
}

.position {
  margin-top: 4px;
  display: block;
}

.contacts {
  margin-top: 8px;
  display: block;
}

.contact {
  display: inline-block;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
}
