.title {
  display: block;
  margin-top: 32px;
  margin-bottom: 24px;

  @media (--tablet) {
    margin-top: 24px;
  }

  @media (--mobile) {
    margin-left: 12px;
  }
}

.content {
  width: 100%;
  max-width: 1222px;
  display: block;
}

.filters {
  display: block;
  margin-bottom: 8px;
}
