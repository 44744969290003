.button {
  display: inline-flex;
  background-color: var(--color-primary-500);
  color: var(--color-white);
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  &:hover {
    background-color: var(--color-primary-600);
  }

  &:active {
    background-color: var(--color-primary-700);
  }

  &:disabled {
    opacity: 0.2;
  }

  &_block {
    width: 100%;
    display: flex;
  }

  &_loading {
    color: transparent;

    .load {
      display: block;
    }
  }

  &_size_m {
    height: 31px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.2px;
    border-radius: 2px;
    padding-right: 8px;
    padding-left: 8px;

    .icon {
      width: 19px;
      height: 19px;
      margin-right: 4px;
    }
  }

  &_size_l {
    height: 36px;
    font-size: 12px;
    letter-spacing: -0.2px;
    border-radius: 3px;
    padding-right: 16px;
    padding-left: 16px;
    font-weight: 500;
  }

  &_size_xl {
    height: 51px;
    font-size: 14px;
    letter-spacing: -0.2px;
    border-radius: 4px;
    padding-right: 16px;
    padding-left: 16px;
    font-weight: 500;
  }

  &_color_white {
    border: 1px solid var(--color-gray-400);
    background: transparent;
    color: var(--color-gray-800);

    .icon {
      color: var(--color-gray-600);
    }

    &:hover {
      background: rgba(226, 231, 238, 0.5);
      border-color: transparent;
    }

    &:active {
      background: var(--color-gray-300);
      border-color: transparent;
    }
  }

  &_color_text {
    border: transparent;
    background: transparent;
    color: var(--color-gray-700);

    &:hover {
      background: rgba(226, 231, 238, 0.65);
      border-color: transparent;
    }

    &:active {
      background: var(--color-gray-300);
      border-color: transparent;
    }
  }
}

.icon {
  margin-right: 8px;
  color: inherit;
  cursor: inherit;
}

.load {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: none;
  color: var(--color-white);
}
