.title {
  display: block;
  color: var(--color-title, var(--color-gray-900));
  font-weight: 600;
  cursor: inherit;
  text-align: inherit;

  &_type_h1 {
    font-size: 34px;
    line-height: 36px;
    letter-spacing: -0.4px;
  }

  &_type_h2 {
    font-size: 29px;
    line-height: 32px;
    letter-spacing: -0.3px;
  }

  &_type_h3 {
    font-size: 21px;
    line-height: 24px;
    letter-spacing: -0.3px;
  }

  &_type_h4 {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.2px;
  }

  &_type_h5 {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.2px;
    font-weight: 500;
  }
}
