.progressBar {
  display: flex;
  width: 86px;
  height: 29px;
  flex-direction: column;

  &_knowledge {
    .brick {
      &::after {
        background: var(--color-additional-500);
      }
    }
  }
}

.value {
  display: flex;
  align-items: center;
}

.text {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: var(--color-gray-900);
  margin-right: 8px;
}

.max {
  font: inherit;
  color: var(--color-gray-500);
}

.bricks {
  display: flex;
  margin-top: auto;
}

.brick {
  height: 4px;
  flex-basis: 0;
  margin-right: 2px;
  flex-grow: 1;
  background-color: var(--color-gray-300);
  border-radius: 1px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(var(--fill) * 1%);
    background: var(--color-positive-500);
  }

  &:last-child {
    margin-right: 0;
  }

  &:only-child {
    border-radius: 2px;
  }
}
