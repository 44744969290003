.table {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tr {
  display: table-row;
}

.th {
  display: table-cell;
  vertical-align: bottom;
  padding-bottom: 8px;

  @media (--mobile) {
    vertical-align: top;
  }

  &:nth-child(1) {
    width: auto;
  }

  &:nth-child(2),
  &:nth-child(3) {
    width: 80px;

    @media (--mobile) {
      width: 40px;
    }
  }

  &:nth-child(2) {
    @media (--mobile) {
      > * {
        display: none;
      }
    }
  }

  &:nth-child(3) {
    > span {
      &:nth-child(1) {
        display: block;

        @media (--mobile) {
          display: none;
        }
      }

      &:nth-child(2) {
        display: none;

        @media (--mobile) {
          display: block;
          text-align: right;
          width: 150px;
          margin-left: -110px;
        }
      }
    }
  }

  &:nth-child(4) {
    width: 180px;

    @media (--mobile) {
      display: none;
    }
  }
}

.td {
  display: table-cell;
  border-top: 1px solid var(--color-gray-300);
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;

  &:nth-child(1) {
    padding-right: 16px;

    > p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &:nth-child(2),
  &:nth-child(3) {
    width: 80px;

    @media (--mobile) {
      width: 40px;
    }
  }

  &:nth-child(4) {
    @media (--mobile) {
      display: none;
    }
  }
}

.label {
  display: block;
  margin-top: 2px;
}

.level {
  display: block;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: var(--color-gray-900);

  &_negative {
    color: var(--color-negative-500);
  }

  &_positive {
    color: var(--color-positive-500);
  }
}
