.wrapper {
  display: flex;
  overflow: hidden;
}

.item {
  width: 176px;
  height: 36px;
  display: block;
  margin-right: 16px;
  flex-shrink: 0;
}
