.list {
  display: block;
}

.item {
  margin-bottom: 1px;
  display: flex;
  position: relative;
}

.download {
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -24px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--color-gray-500);
  }

  + .link {
    padding-left: 48px;
  }
}

.link {
  display: flex;
  height: 100px;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-between;
  background: var(--color-white);
  box-shadow: 0px 0px 3px rgba(3, 30, 120, 0.1);
  border-radius: 4px;
  transition: background 0.3s ease;
  flex-grow: 1;

  &:link {
    cursor: pointer;

    &:hover {
      background: var(--color-gray-100);
    }
  }

  @media (--mobile) {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    height: auto;
    flex-wrap: wrap;
  }
}

.label {
  display: block;
  margin-top: 4px;
  cursor: inherit;
}

.date {
  display: flex;
  align-items: center;
  cursor: inherit;

  @media (--mobile) {
    width: 100%;
    margin-top: 12px;
  }
}

.icon {
  margin-right: 8px;
  color: var(--color-gray-500);
  cursor: inherit;
}
