html,
body {
  display: block;
}

:focus:not(input) {
  outline: rgba(0, 103, 244, 0.247) auto 5px !important;
}

[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  outline: none !important;
}

svg {
  display: block;
  color: inherit;
  cursor: inherit;
}
