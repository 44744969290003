.header {
  padding-left: var(--indent);
  padding-right: var(--indent);
  padding-top: var(--indent);
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.photo {
  margin-right: 24px;
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;

  @media (--mobile) {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
}

.title {
  height: 20px;
  width: 100%;
}

.line {
  height: 37px;
  margin-bottom: 16px;
  margin-left: var(--indent);
  margin-right: var(--indent);
}

.footer {
  margin-top: 49px;
  height: 37px;
  max-width: 279px;
  margin-left: var(--indent);
  margin-right: var(--indent);
  margin-bottom: var(--indent);

  @media (--mobile) {
    margin-top: 32px;
  }
}
