.list {
  display: block;
}

.item {
  margin-bottom: 1px;
  display: block;
}

.link {
  display: flex;
  height: 90px;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: flex-start;
  background: var(--color-white);
  box-shadow: 0px 0px 3px rgba(3, 30, 120, 0.1);
  border-radius: 4px;
  transition: background 0.3s ease;
  width: 100%;
  box-sizing: border-box;

  &:not(:disabled):hover {
    cursor: pointer;
    background: var(--color-gray-100);
  }
}

.iconWrapper {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);

  &_lesson {
    background: var(--color-gray-400);
  }

  &_test {
    background: var(--color-additional-500);
  }

  &_quiz {
    background: var(--color-positive-500);
  }
}

.info {
  display: block;
  margin-left: 16px;
  cursor: inherit;

  > p {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    max-height: 40px;
  }
}

.label {
  display: block;
  margin-top: 4px;
  cursor: inherit;
}

.date {
  display: flex;
  align-items: center;
  cursor: inherit;
}

.icon {
  margin-right: 8px;
  color: var(--color-gray-500);
  cursor: inherit;
}
