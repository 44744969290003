@font-face {
  font-family: 'Graphik LC';
  src: url('/fonts/Graphik-Regular-Web.woff2') format('woff2');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Graphik LC';
  src: url('/fonts/Graphik-Medium-Web.woff2') format('woff2');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Graphik LC';
  src: url('/fonts/Graphik-Semibold-Web.woff2') format('woff2');
  font-display: swap;
  font-weight: 600;
}
