.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(51, 58, 74, 0.9);
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  overflow-y: auto;
  padding-top: 44px;
  padding-bottom: 40px;
  padding-left: 42px;
  padding-right: 42px;
  z-index: 1;

  &:global(.ReactModal__Overlay--after-open) {
    opacity: 1;
  }

  &:global(.ReactModal__Overlay--before-close) {
    opacity: 0;
  }

  @media (--mobile) {
    padding: 0;
  }
}

.modal {
  background: var(--color-white);
  display: block;
  border-radius: 4px;
  position: relative;
  height: max-content;

  @media (--mobile) {
    min-height: 100%;
    width: 100%;
  }
}

.close {
  left: 100%;
  bottom: 100%;
  position: absolute;
  display: block;

  @media (--mobile) {
    display: none;
  }

  &_mobile {
    display: none;
    position: static;
    margin-top: 8px;
    margin-left: 4px;

    @media (--mobile) {
      display: block;
    }
  }
}
