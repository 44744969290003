.content {
  width: 616px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
}

.icon {
  display: block;
  margin-bottom: 16px;
  color: var(--color-negative-500);
}

.info {
  text-align: inherit;
  margin-top: 8px;
}

.buttons {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.button {
  width: 222px;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
}
