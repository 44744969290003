.page {
  display: flex;
  background: var(--color-gray-200);
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
}

.content {
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 32px;
  flex-grow: 1;
  flex-direction: column;

  @media (--tablet) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (--mobile) {
    padding-left: 0;
    padding-right: 0;
  }
}
