.courses {
  display: block;
  margin-right: 8px;
}

.menu-laptop {
  display: flex;
  @media (--mobile) {
    display: none;
  }
}

.menu-mobile {
  display: none;
  padding-bottom: 5px;
  background: var(--color-white);
  @media (--mobile) {
    display: flex;
    padding-left: 12px;
    padding-right: 14px;
    padding-bottom: 5px;
  }
}

.btn-selfdiagnosis__laptop {
  display: block;
  @media (--mobile) {
    display: none;
  }
}

.btn-selfdiagnosis__mobile {
  display: none;
  @media (--mobile) {
    display: block;
  }
}
