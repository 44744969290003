.wrapper {
  display: block;
  padding: var(--indent);
}

.line {
  display: block;
  width: 100%;
  height: 37px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
