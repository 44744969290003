.content {
  max-width: 100%;
  width: 650px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media (--mobile) {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 16px;
    padding-top: 8px;
    width: 100vw;
  }
}

.info {
  text-align: inherit;
  margin-top: 8px;
  margin-bottom: 16px;
}

.line {
  margin-top: 8px;
  display: flex;
  width: 100%;

  @media (--mobile) {
    flex-wrap: wrap;
  }
}

.field {
  display: block;
  margin-right: 8px;
  flex-basis: 0;
  flex-grow: 1;

  @media (--mobile) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
    flex-basis: initial;
  }

  &:last-child {
    margin-right: 0;

    @media (--mobile) {
      margin-bottom: 0;
    }
  }
}

.button {
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  width: 203px;
  display: block;

  @media (--mobile) {
    width: 100%;
  }
}
