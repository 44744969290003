.control {
  background: var(--color-gray-200);
  border-radius: 4px;
  height: 52px;
  width: 100%;
  display: flex;
  padding-left: 16px;
  padding-right: 12px;
  box-sizing: border-box;
  border: 1px solid var(--color-gray-200);
  transition: border-color 0.3s ease;

  &:not(&_focused):not(&_disabled):hover {
    border-color: var(--color-primary-200);
  }

  &_focused {
    border-color: var(--color-primary-500);
  }

  &_disabled {
    opacity: 0.55;
  }
}

.valueContainer {
  display: flex;
  flex-grow: 1;
  padding-top: 9px;
  padding-bottom: 9px;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  position: relative;

  input {
    font-size: 14px !important;
    line-height: 17px;
    letter-spacing: -0.2px;
    color: var(--color-gray-900);
    margin-left: -2px;
  }
}

.label {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.2px;
  color: var(--color-gray-600);
  margin-bottom: 4px;
  display: none;
  position: absolute;
  top: 9px;

  &_visible {
    display: block;

    ~ * input {
      margin-top: 20px;
    }
  }
}

.placeholder {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: var(--color-gray-500);
  position: absolute;
  width: 100%;
}

.value {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: var(--color-gray-900);
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  position: absolute;
  top: 26px;
  width: 100%;
}

.icon {
  display: block;
  color: var(--color-gray-500);

  &_focused {
    color: var(--color-primary-600);
  }
}

.menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 4px;
  background: #ffffff;
  box-shadow: 0px 8px 18px 5px rgba(3, 30, 120, 0.08);
  border-radius: 3px;
  display: block;
  z-index: 1;
}

.option {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;

  &_focused,
  &_selected {
    background-color: var(--color-gray-200);
  }

  &:active {
    background-color: var(--color-gray-300);
  }

  &_disabled {
    opacity: 0.3;
  }
}

.optionIcon {
  margin-right: 16px;
  color: var(--color-gray-500);
}

.optionLabel {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: var(--color-gray-900);
}

.notFound {
  display: block;
  text-align: left;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.2px;
  opacity: 0.3;
  color: var(--color-gray-900);
  padding: 16px;
}
