.header {
  height: 90px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
  user-select: none;

  @media (--mobile) {
    flex-wrap: wrap;
    height: auto;
    padding-top: 20px;
    padding-right: 12px;
    padding-left: 12px;
    padding-bottom: 20px;
  }

  &:not(&_subgroup):hover {
    background-color: var(--color-gray-100);
  }

  &_subgroup {
    height: 74px;
    background-color: var(--color-gray-200);

    .icon {
      display: none;
    }

    @media (--mobile) {
      flex-wrap: wrap;
      height: auto;
      padding-top: 6px;
      padding-right: 6px;
    }
  }
}

.icon {
  margin-right: 16px;
  width: 24px;
  height: 24px;
  color: var(--color-gray-500);
}

.right {
  display: flex;
  margin-left: auto;
  align-items: flex-start;
  margin-right: 8px;
  flex-shrink: 0;

  @media (--mobile) {
    order: 1;
    width: 100%;
    margin-top: 8px;
    flex-wrap: wrap;

    &:empty {
      display: none;
    }
  }
}

.button {
  display: block;

  @media (--mobile) {
    margin-left: auto;
  }
}

.item {
  margin-right: 16px;
  flex-shrink: 0;

  &_duration {
    @media (--mobile) {
      order: 1;
      width: 100%;
      margin-top: 16px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.content {
  display: none;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;

  &_visible {
    display: block;
  }

  &_subgroup {
    background-color: var(--color-gray-200);
  }
}

.inner {
  border-radius: 4px;
  display: block;
  width: 100%;
}
