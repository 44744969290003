.text {
  display: block;
  color: inherit;
  cursor: inherit;

  &_size_l {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }

  &_size_m {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.2px;
  }

  &_size_s {
    font-weight: 500;
    font-size: 9px;
    line-height: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  &_color_900 {
    color: var(--color-gray-900);
  }

  &_color_600 {
    color: var(--color-gray-600);
  }

  &_color_500 {
    color: var(--color-gray-500);
  }

  &_color_negative500 {
    color: var(--color-negative-500);
  }

  &_color_primary500 {
    color: var(--color-primary-500);
  }

  &_color_positive500 {
    color: var(--color-positive-500);
  }
}
