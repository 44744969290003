:root {
  --color-black: #000;
  --color-white: #fff;

  --color-gray-900: #262b37;
  --color-gray-800: #333a4a;
  --color-gray-700: #474f60;
  --color-gray-600: #727c90;
  --color-gray-500: #a6afc0;
  --color-gray-400: #cdd4de;
  --color-gray-300: #e2e7ee;
  --color-gray-200: #f4f6f9;
  --color-gray-100: #f9fafc;

  --color-additional-500: #ffbf00;
  --color-additional-100: #fff7ce;

  --color-positive-500: #1abf7e;

  --color-negative-500: #fc3848;
  --color-negative-300: #ffa4ac;
  --color-negative-100: #ffedef;

  --color-primary-700: #083ab4;
  --color-primary-600: #0c4cd7;
  --color-primary-500: #0c64f5;
  --color-primary-400: #2a79fc;
  --color-primary-300: #6ab0ff;
  --color-primary-200: #c7e2ff;
  --color-primary-100: #ebf5ff;
}
