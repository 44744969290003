.group {
  padding-top: var(--indent);
  padding-left: var(--indent);
  padding-right: var(--indent);
  padding-bottom: var(--indent);
  border-bottom: 1px solid var(--color-gray-300);

  &:last-child {
    border-bottom: 0;
  }
}

.title {
  display: block;
  margin-bottom: 16px;
}

.list {
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;

  &:first-child {
    margin-top: 0;
  }

  @media (--mobile) {
    margin-bottom: -16px;
  }

  > * {
    width: 25%;
    padding-right: 24px;
    box-sizing: border-box;
    margin-bottom: 16px;

    @media (--mobile) {
      width: 154px;

      &:only-child {
        width: 100%;
      }

      &:nth-child(2n) {
        width: calc(100% - 154px);
      }
    }
  }

  &_vertical {
    flex-direction: column;

    @media (--mobile) {
      margin-bottom: 0;
    }

    > * {
      width: auto;
      padding-right: 0;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.chief {
  display: flex;
  margin-top: 24px;
}

.chiefPhoto {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}
